@import "/styles/tools";
@import "/styles/ui";

.footnote {
  border-top: rem(1) solid token("general-divide-light");
  padding-top: rem(40);
  padding-bottom: rem(40);

  @include above(md) {
    padding-top: rem(64);
    padding-bottom: rem(64);
  }

  &__container {
    @include container;
  }

  &__inner {
    display: grid;
    background-color: token("general-accent");
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: rem(24);
    padding: rem(40) rem(20);

    @include above(md) {
      gap: rem(20);
      grid-template-columns: 3fr 1fr;
      padding: rem(64);
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: rem(24);
    padding: 0 0 0 rem(20);
    border-left: rem(2) solid token("interaction-primary");

    @include above(md) {
      padding: 0 rem(40);
    }
  }

  &__leftTop {
    display: flex;
  }

  &__relatedTopics {
    display: flex;
    flex-direction: column;
    gap: rem(24);
  }

  &__relatedTitle {
    @include typeHeadline(20);
    color: token("primary-on-general-accent");
  }

  &__relatedList {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);

    @include above(md) {
      gap: rem(12);
    }
  }

  &__authorImage {
    border-radius: 50%;
    overflow: hidden;
    width: rem(48);
    height: rem(48);
    object-fit: cover;
  }

  &__authorInfo {
    display: flex;
    flex-direction: column;
  }

  &__authorName {
    @include typeLabel(16, bold);
    color: token("primary-on-general-accent");
  }

  &__authorJobTitle {
    @include typeLabel(16);
    color: token("secondary-on-general-accent");
  }

  &__authorBio {
    width: min(100%, rem(700));
    color: token("secondary-on-general-accent");

    @media screen and (max-width: 350px) {
      font-size: rem(14);
    }

    [class*="richTextWrapper__inner"] {
      color: inherit;
      font-size: inherit;
    }
  }
}
