@import "/styles/tools";
@import "/styles/ui";

.inThisArticle {
  padding-bottom: rem(64);

  &__title {
    @include typeHeadline(20);
    color: token("primary-on-general-light");
    margin-bottom: rem(24);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: rem(16);
  }

  &__listItem {
    @include typeLabel(16);
    display: inline-flex;
    align-items: center;
    gap: rem(8);

    &:before {
      width: rem(16);
      height: rem(16);
      background-image: map-get($icons, "page-arrow");
      background-size: rem(11) rem(9);
      background-repeat: no-repeat;
      background-position: center;
      content: "";
    }

    a {
      color: token("secondary-on-general-light");
      &:hover,
      &:focus-visible {
        text-decoration: underline;
        color: token("interaction-on-primary");
      }
    }
  }
}
