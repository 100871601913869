@import "/styles/tools";
@import "/styles/ui";

.articleContent {
  @include vertical-spacing;
  border-top: rem(1) solid token("general-divide-light");
  border-bottom: rem(1) solid token("general-divide-light");

  &__container {
    @include container;
  }

  &__grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: rem(56);
    padding-bottom: rem(64);

    @include above(md) {
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-template-rows: 1fr;
      grid-column-gap: rem(24);
    }
  }

  &__main {
    @include above(md) {
      grid-column: span 8;

      &.full {
        grid-column: span 12;
      }
    }
  }

  &__text {
    @include rte;
    @include typeHeadline(20);
    color: token("secondary-on-general-light");
    max-width: rem(735);

    @include above(md) {
      @include typeHeadline(28);
    }
  }

  &__ctaContainer {
    margin-top: rem(24);

    @include above(md) {
      margin-top: rem(40);
    }
  }

  &__articleSection {
    margin-bottom: rem(56);
    position: relative;
  }

  &__articleSectionAnchor {
    position: absolute;
    top: rem(-140);
    left: 0;
  }

  &__articleSectionTitle {
    @include typeHeadline(24, bold);
    color: token("primary-on-general-light");
    margin-bottom: rem(16);

    @include above(md) {
      margin-bottom: rem(24);
    }
  }

  &__articleSectionBody {
    h3,
    h4 {
      @include typeHeadline(20, bold);
      color: token("secondary-on-general-light");
      margin-bottom: rem(16);

      @include above(md) {
        margin-bottom: rem(24);
      }
    }

    blockquote {
      @include typeHeadline(20);
      color: token("secondary-on-general-light");
      margin-top: rem(48);
      margin-bottom: rem(48);

      @include above("md") {
        font-size: rem(28);
        margin-top: rem(56);
        margin-bottom: rem(56);
      }
    }
  }

  &__aside {
    @include above(md) {
      grid-column: 10 / -1;
    }
  }

  &__asideInner {
    padding-bottom: rem(16);
    position: sticky;
    top: rem(140);
    background-color: token("general-light");

    @include above(md) {
      max-width: rem(300);
    }
  }

  &__textBody {
    img {
      margin-right: rem(8);
    }
  }

  &__mediaCard {
    padding-top: rem(48);
    padding-bottom: rem(32);

    @include above("md") {
      padding-top: rem(56);
      padding-bottom: rem(32);
    }
  }

  &__componentCta {
    margin-top: rem(32);

    @include above(md) {
      margin-top: rem(64);
    }
  }

  &__footnoteContainer {
    background-color: token("general-accent");
    padding: rem(40) rem(20);

    @include above("md") {
      padding: rem(30);
    }

    @include above("lg") {
      padding: rem(64);
    }
  }

  & + [class^="ComponentInsightListing_insightCardListing__"],
  & + [class^="ComponentMultiCardCallout_multiCardCallout__"] {
    margin-top: rem(0) !important;
    border-top: 0 !important;
  }

  & + [class^="ComponentInsightListing_insightCardListing__"] {
    border-top: rem(1) solid token("general-divide-light");
  }
}
